"use client";
import { PageContainer } from "@components";
import { Button } from "@shadcn-ui/button";
import Link from "next/link";

const Error = () => {
  return (
    <PageContainer title="Something wrong">
      <div className="grid size-full place-content-center">
        <Button>
          <Link href="/">Return Home</Link>
        </Button>
      </div>
    </PageContainer>
  );
};

export default Error;
